/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Container,
  Button,
  DropdownButton,
  FormControl,
  InputGroup,
  Dropdown,
  Form,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';

export default function TermsOfUse() {
  const admin = useSelector(state => state.auth.admin);

  const [loja, setLoja] = useState('');

  const [textTerms, setTextTerms] = useState(false);

  const handleGetTerms = useCallback(async valueLoja => {
    try {
      const response = await api.get(`terms/${valueLoja}`);

      const { err, text } = response.data;

      if (err) {
        toast.error(err);
        return;
      }

      if (text) {
        setTextTerms(text);
      } else {
        setTextTerms('Obs: Pode usar parágrafos e emotions');
      }
    } catch (error) {
      toast.error('Não foi possível obter texto');
    }
  }, []);

  const handleSubmitMessage = useCallback(async () => {
    try {
      const response = await api.put('terms', { text: textTerms, loja });

      const { err, success } = response.data;

      if (err) {
        toast.error(err);
        return;
      }

      if (success) {
        toast.success(success);

        setTextTerms(false);
        setLoja('');
      }
    } catch (error) {
      toast.error('Não foi possível atualizar texto');
    }
  }, [loja, textTerms]);

  return (
    <>
      {admin && (
        <>
          <br />
          <br />
          <br />
          <div
            style={{
              margin: 'auto',
              background: '#fff',
              width: '95%',
              maxWidth: '700px',
              padding: '24px',
              borderRadius: '5px',
              position: 'relative',
            }}
          >
            <Container>
              <h3>Termos de Uso</h3>
              <br />
              <div name="Selecionar loja">
                <InputGroup className="mb-3">
                  <DropdownButton
                    as={InputGroup.Prepend}
                    variant="outline-success"
                    title="Loja"
                    id="lojaSelect"
                  >
                    <Dropdown.Item
                      value="VIX"
                      onClick={() => {
                        setLoja('VIX');
                        handleGetTerms('VIX');
                      }}
                    >
                      Vitória
                    </Dropdown.Item>

                    <Dropdown.Item
                      value="VV"
                      onClick={() => {
                        setLoja('VV');
                        handleGetTerms('VV');
                      }}
                    >
                      Vila Velha
                    </Dropdown.Item>
                  </DropdownButton>
                  <FormControl
                    aria-describedby="basic-addon1"
                    value={
                      loja !== '' && loja === 'VIX'
                        ? 'Vitória'
                        : loja === 'VV'
                        ? 'Vila Velha'
                        : 'Selecione uma filial'
                    }
                  />
                </InputGroup>
              </div>

              {loja && (
                <>
                  <Form.Label>Texto</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="10"
                    value={textTerms}
                    onChange={event => setTextTerms(event.target.value)}
                  />

                  <br />

                  <Button
                    size="sm"
                    block
                    style={{ background: '#3e8f0c' }}
                    onClick={() => handleSubmitMessage()}
                  >
                    Salvar
                  </Button>
                </>
              )}
            </Container>
          </div>
        </>
      )}
    </>
  );
}
