import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { Spinner, Form, Button } from 'react-bootstrap';

import { signInRequest } from '../../store/modules/auth/actions';

const schema = Yup.object().shape({
  username: Yup.string().required('Campo username é obrigatório'),
  password: Yup.string()
    .min(6, 'Senha incorreta')
    .required('Campo de senha é obrigatório'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const handleSubmit = useCallback(() => {
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;

    schema
      .isValid({ username, password })
      .then(valid => {
        if (valid) {
          dispatch(signInRequest(username, password));
        } else {
          toast.error('Por favor, verifique os campos');
        }
      })
      .catch(() => {
        toast.error('Por favor, verifique os campos');
      });
  }, [dispatch]);

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        handleSubmit();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleSubmit]);

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          margin: '10rem auto',
          background: '#fff',
          height: '18rem',
          width: '25vw',
          minWidth: '300px',
          padding: '24px',
          borderRadius: '5px',
        }}
      >
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Usuário</Form.Label>
            <Form.Control
              type="text"
              placeholder="Entre com seu username"
              id="username"
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Senha</Form.Label>
            <Form.Control
              type="password"
              placeholder="Digite sua senha"
              id="password"
            />
          </Form.Group>
          <br />

          {loading ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <Button
              variant="success"
              type="button"
              block
              onClick={() => handleSubmit()}
            >
              Entrar
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
}
