import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Catalog from '../pages/Catalog';
import SignIn from '../pages/SignIn';

import Loja from '../pages/Loja';
import Historic from '../pages/Historic';
import WhatsApp from '../pages/WhatsApp';
import Messages from '../pages/Messages';
import ConfigParams from '../pages/ConfigParams';
import Admin from '../pages/Admin';
import Statistics from '../pages/Statistics';
import AddUser from '../pages/AddUser';
import Products from '../pages/Products';
import TermsOfUse from '../pages/TermsOfUse';
import LogInfo from '../pages/LogInfo';
import ExportCSV from '../pages/ExportCSV';
import Reports from '../pages/Reports';

// Apenas autenticado

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Catalog} />
      <Route path="/login" exact component={SignIn} />

      <Route path="/loja" exact component={Loja} isPrivate />
      <Route path="/historico" exact component={Historic} isPrivate />
      <Route path="/whatsapp" exact component={WhatsApp} isPrivate />
      <Route path="/admin/config" exact component={ConfigParams} isPrivate />
      <Route path="/admin/mensagens" exact component={Messages} isPrivate />
      <Route path="/admin/usuarios" exact component={AddUser} isPrivate />
      <Route path="/admin/produtos" exact component={Products} isPrivate />
      <Route path="/admin/termosDeUso" exact component={TermsOfUse} isPrivate />
      <Route
        path="/admin/estatisticas"
        exact
        component={Statistics}
        isPrivate
      />
      <Route path="/admin/logs" exact component={LogInfo} isPrivate />
      <Route path="/admin/export" exact component={ExportCSV} isPrivate />
      <Route path="/admin/reports" exact component={Reports} isPrivate />

      <Route path="/admin" exact component={Admin} isPrivate />

      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}
