import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  Container,
  InputGroup,
  DropdownButton,
  Dropdown,
  FormControl,
  Form,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Table,
  Card,
  ListGroup,
} from 'react-bootstrap';
import { FiTrash2 } from 'react-icons/fi';
import { AiOutlineEdit } from 'react-icons/ai';

import { toast } from 'react-toastify';
import api from '../../services/api';
import { Scroll } from './styles';

export default function Messages() {
  const admin = useSelector(state => state.auth.admin);

  const [allMessagesByType, setAllMessagesByType] = useState(false);

  const [addMessagePage, setAddMessagePage] = useState(false);
  const [editMessagePage, setEditMessagePage] = useState(false);

  const [typeMessage, setTypeMessage] = useState(false);
  const [formattedMessage, setFormattedMessage] = useState('');
  const [textMessage, setTextMessage] = useState('');
  const [textMessageEdit, setTextMessageEdit] = useState('');
  const [idMsgEditing, setIdMsgEditing] = useState(false);

  const [loja, setLoja] = useState('');

  const resetFields = useCallback(() => {
    setTypeMessage(false);
    setFormattedMessage('');
    setTextMessage('');
    setAllMessagesByType(false);
    setLoja('');
    setTextMessageEdit('');
    setIdMsgEditing(false);
  }, []);

  const handleChangePage = useCallback(
    event => {
      if (event === 'addMessagePage') {
        resetFields();
        setAddMessagePage(true);
        setEditMessagePage(false);
      }

      if (event === 'editMessagePage') {
        resetFields();
        setAddMessagePage(false);
        setEditMessagePage(true);
      }
    },
    [resetFields]
  );

  const handleSubmit = useCallback(async () => {
    try {
      if (!typeMessage) {
        toast.error('Selecione um tipo');
      }

      if (!textMessage || textMessage === '') {
        toast.error('Digite a mensagem');
      }

      const response = await api.post('messages', {
        message: textMessage,
        typeMessage,
        loja,
      });

      const { err, success } = response.data;

      if (err) {
        toast.error(err);
        return;
      }

      if (success) {
        toast.success(success);
        resetFields();
      }
    } catch (error) {
      toast.error('Erro ao salvar mensagem');
    }
  }, [loja, resetFields, textMessage, typeMessage]);

  const handleUpdateMessage = useCallback(async () => {
    if (!idMsgEditing) {
      toast.error(
        'Erro ao obter o ID da mensagem. Tente atualizar a página e fazer novamente'
      );
      return;
    }

    if (!textMessageEdit) {
      toast.error(
        'Não é possível atualizar a mensagem. O campo de mensagem não está preenchido'
      );
      return;
    }

    const response = await api.put('messages', {
      _id: idMsgEditing,
      message: textMessageEdit,
    });

    const { err, success } = response.data;

    if (err) {
      toast.error(err);
      return;
    }

    if (success) {
      toast.success(success);
      setAllMessagesByType(
        allMessagesByType.map(x => {
          if (x._id === idMsgEditing) {
            x.message = textMessageEdit;
            return x;
          }
          return x;
        })
      );

      setIdMsgEditing(false);
      setTextMessageEdit('');
    }
  }, [allMessagesByType, idMsgEditing, textMessageEdit]);

  const handleGetMessagesByType = useCallback(
    async typeMessageGet => {
      if (typeMessageGet) {
        const response = await api.get(`messages/${loja}/${typeMessageGet}`);

        const { err } = response.data;

        if (err) {
          toast.error(err);
        } else {
          setAllMessagesByType(response.data);
        }
      }
    },
    [loja]
  );

  const handleDeleteMessage = useCallback(
    async messageId => {
      if (messageId) {
        const response = await api.delete(`messages/${messageId}`);

        const { err, success } = response.data;

        if (err) {
          toast.error(err);
          return;
        }

        if (success) {
          toast.success(success);

          resetFields();
        }
      }
    },
    [resetFields]
  );

  return (
    <>
      {admin ? (
        <>
          {' '}
          <br />
          <br />
          <br />
          <div
            style={{
              margin: 'auto',
              background: '#fff',
              width: '95%',
              maxWidth: '700px',
              padding: '24px',
              borderRadius: '5px',
              position: 'relative',
            }}
          >
            <div style={{ display: 'flex', margin: '0 auto' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {' '}
                  <ToggleButtonGroup type="radio" name="changePage">
                    <ToggleButton
                      style={{ background: '#3e8f0c', color: '#fff' }}
                      value="addMessagePage"
                      onClick={() => handleChangePage('addMessagePage')}
                    >
                      Adicionar Mensagem
                    </ToggleButton>
                    <ToggleButton
                      style={{
                        background: '#3e8f0c',
                        color: '#fff',
                        marginLeft: '1rem',
                      }}
                      value="editMessagePage"
                      onClick={() => handleChangePage('editMessagePage')}
                    >
                      Ver Mensagens
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
              <div style={{ display: 'flex', marginLeft: '2rem' }}>
                <Card style={{ width: '18rem' }}>
                  <Card.Header>
                    <strong>Variáveis Disponíveis:</strong>
                  </Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <strong>NOME</strong> - Primeiro nome do cliente.
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>PONTOS_ATUAIS</strong>
                      <span> - Pontos que o cliente tem atualmente.</span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>PONTUACAO_VENDA</strong>
                      <span>
                        &nbsp;- Quantidade de pontos obtidos na última compra.
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>EXPIRA</strong>
                      <span>
                        &nbsp;- Data de expiração dos pontos obtidos na última
                        compra.
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>PONTOS_RESGATADOS</strong>
                      <span>
                        &nbsp;- Pontos que o cliente resgatou na última compra.
                      </span>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </div>
            </div>

            {addMessagePage ? (
              <>
                {' '}
                <Container>
                  <h4 className="h4" style={{ marginTop: '1rem' }}>
                    Adicionar Mensagem
                  </h4>
                  <br />
                  <InputGroup className="mb-3">
                    <DropdownButton
                      as={InputGroup.Prepend}
                      variant="outline-secondary"
                      title="Loja"
                      id="lojaSelect"
                    >
                      <Dropdown.Item
                        value="VIX"
                        onClick={() => {
                          setLoja('VIX');
                        }}
                      >
                        Vitória
                      </Dropdown.Item>

                      <Dropdown.Item
                        value="VV"
                        onClick={() => {
                          setLoja('VV');
                        }}
                      >
                        Vila Velha
                      </Dropdown.Item>
                    </DropdownButton>
                    <FormControl
                      aria-describedby="basic-addon1"
                      value={loja}
                      disabled
                    />
                  </InputGroup>

                  {loja && (
                    <InputGroup className="mb-3">
                      <DropdownButton
                        as={InputGroup.Prepend}
                        variant="outline-secondary"
                        title="Tipo"
                        id="typeMessage"
                      >
                        <Dropdown.Item
                          value="confirm_pontuacao"
                          onClick={() => {
                            setTypeMessage('confirm_pontuacao');
                            setFormattedMessage('Confirmação Pontuação');
                          }}
                        >
                          Confirm Pontuação
                        </Dropdown.Item>

                        <Dropdown.Item
                          value="confirm_resgate"
                          onClick={() => {
                            setTypeMessage('confirm_resgate');
                            setFormattedMessage('Confirmação Resgate');
                          }}
                        >
                          Confirm Resgate
                        </Dropdown.Item>

                        <Dropdown.Item
                          value="boomerang_7"
                          onClick={() => {
                            setTypeMessage('boomerang_7');
                            setFormattedMessage('Boomerang 7');
                          }}
                        >
                          Boomerang 7
                        </Dropdown.Item>

                        <Dropdown.Item
                          value="boomerang_30"
                          onClick={() => {
                            setTypeMessage('boomerang_30');
                            setFormattedMessage('Boomerang 30');
                          }}
                        >
                          Boomerang 30
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Item
                          value="confirm_pontuacao_ifood"
                          onClick={() => {
                            setTypeMessage('confirm_pontuacao_ifood');
                            setFormattedMessage(
                              'Confirmação Pontuação - iFood'
                            );
                          }}
                        >
                          Confirm Pontuação - iFood
                        </Dropdown.Item>

                        <Dropdown.Item
                          value="boomerang_7_ifood"
                          onClick={() => {
                            setTypeMessage('boomerang_7_ifood');
                            setFormattedMessage('Boomerang 7 - iFood');
                          }}
                        >
                          Boomerang 7 - iFood
                        </Dropdown.Item>

                        <Dropdown.Item
                          value="boomerang_30_ifood"
                          onClick={() => {
                            setTypeMessage('boomerang_30_ifood');
                            setFormattedMessage('Boomerang 30 - iFood');
                          }}
                        >
                          Boomerang 30 - iFood
                        </Dropdown.Item>
                      </DropdownButton>
                      <FormControl
                        aria-describedby="basic-addon1"
                        value={formattedMessage}
                      />
                    </InputGroup>
                  )}
                </Container>
                <br />
                {typeMessage ? (
                  <Container>
                    <Form.Group controlId="textMessage">
                      <Form.Label>Mensagem:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="6"
                        value={textMessage}
                        onChange={event => setTextMessage(event.target.value)}
                      />
                    </Form.Group>

                    <br />
                    <br />

                    <Button
                      variant="success"
                      size="lg"
                      block
                      onClick={() => handleSubmit()}
                    >
                      Salvar
                    </Button>
                  </Container>
                ) : null}
              </>
            ) : null}

            {editMessagePage ? (
              <>
                {' '}
                <Container>
                  <h4 className="h4" style={{ marginTop: '1rem' }}>
                    Listar Mensagens
                  </h4>
                  <br />
                  <br />
                  <InputGroup className="mb-3">
                    <DropdownButton
                      as={InputGroup.Prepend}
                      variant="outline-secondary"
                      title="Loja"
                      id="lojaSelect"
                    >
                      <Dropdown.Item
                        value="VIX"
                        onClick={() => {
                          setLoja('VIX');
                          setAllMessagesByType(false);
                        }}
                      >
                        Vitória
                      </Dropdown.Item>

                      <Dropdown.Item
                        value="VV"
                        onClick={() => {
                          setLoja('VV');
                          setAllMessagesByType(false);
                        }}
                      >
                        Vila Velha
                      </Dropdown.Item>
                    </DropdownButton>
                    <FormControl
                      aria-describedby="basic-addon1"
                      value={loja}
                      disabled
                    />
                  </InputGroup>

                  {loja && (
                    <InputGroup className="mb-3">
                      <DropdownButton
                        as={InputGroup.Prepend}
                        variant="outline-secondary"
                        title="Tipo"
                        id="typeMessage"
                      >
                        <Dropdown.Item
                          value="confirm_pontuacao"
                          onClick={() => {
                            handleGetMessagesByType('confirm_pontuacao');
                            setFormattedMessage('Confirmação Pontuação');
                          }}
                        >
                          Confirm Pontuação
                        </Dropdown.Item>

                        <Dropdown.Item
                          value="confirm_resgate"
                          onClick={() => {
                            handleGetMessagesByType('confirm_resgate');
                            setFormattedMessage('Confirmação Resgate');
                          }}
                        >
                          Confirm Resgate
                        </Dropdown.Item>

                        <Dropdown.Item
                          value="boomerang_7"
                          onClick={() => {
                            handleGetMessagesByType('boomerang_7');
                            setFormattedMessage('Boomerang 7');
                          }}
                        >
                          Boomerang 7
                        </Dropdown.Item>

                        <Dropdown.Item
                          value="boomerang_30"
                          onClick={() => {
                            handleGetMessagesByType('boomerang_30');
                            setFormattedMessage('Boomerang 30');
                          }}
                        >
                          Boomerang 30
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Item
                          value="confirm_pontuacao_ifood"
                          onClick={() => {
                            handleGetMessagesByType('confirm_pontuacao_ifood');
                            setFormattedMessage(
                              'Confirmação Pontuação - iFood'
                            );
                          }}
                        >
                          Confirm Pontuação - iFood
                        </Dropdown.Item>

                        <Dropdown.Item
                          value="boomerang_7_ifood"
                          onClick={() => {
                            handleGetMessagesByType('boomerang_7_ifood');
                            setFormattedMessage('Boomerang 7 - iFood');
                          }}
                        >
                          Boomerang 7 - iFood
                        </Dropdown.Item>

                        <Dropdown.Item
                          value="boomerang_30_ifood"
                          onClick={() => {
                            handleGetMessagesByType('boomerang_30_ifood');
                            setFormattedMessage('Boomerang 30 - iFood');
                          }}
                        >
                          Boomerang 30 - iFood
                        </Dropdown.Item>
                      </DropdownButton>
                      <FormControl
                        aria-describedby="basic-addon1"
                        value={formattedMessage}
                        disabled
                      />
                    </InputGroup>
                  )}
                </Container>
                <br />
                {allMessagesByType && (
                  <Container>
                    <Scroll style={{ border: 'solid 1px #eee' }}>
                      <Table responsive striped>
                        <thead style={{ background: '#3e8f0c', color: '#fff' }}>
                          <tr>
                            <th colSpan="3">Mensagem</th>
                            <th colSpan="1">Editar</th>
                            <th colSpan="1">Excluir</th>
                          </tr>
                        </thead>

                        <tbody>
                          {allMessagesByType.map(x => {
                            return (
                              <tr>
                                <td style={{ whiteSpace: 'pre-line' }}>
                                  {x.message}
                                </td>
                                <td colSpan="3">
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      setIdMsgEditing(x._id);
                                      setTextMessageEdit(x.message);
                                    }}
                                  >
                                    <AiOutlineEdit />
                                  </Button>
                                </td>
                                <td colSpan="3">
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() => handleDeleteMessage(x._id)}
                                  >
                                    <FiTrash2 />
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Scroll>

                    {idMsgEditing ? (
                      <Container>
                        <Form.Group controlId="textMessage">
                          <Form.Label>
                            {' '}
                            <strong>Atualizar Mensagem:</strong>{' '}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows="6"
                            value={textMessageEdit}
                            onChange={event =>
                              setTextMessageEdit(event.target.value)
                            }
                          />
                        </Form.Group>

                        <br />
                        <br />

                        <Button
                          variant="success"
                          size="sm"
                          block
                          onClick={() => handleUpdateMessage()}
                        >
                          Atualizar
                        </Button>
                      </Container>
                    ) : null}

                    <br />
                    <br />
                  </Container>
                )}
              </>
            ) : null}
          </div>
        </>
      ) : null}
    </>
  );
}
