import React, { useState, useCallback } from 'react';

import { Container, Form, Button, InputGroup, Spinner } from 'react-bootstrap';
import { AiOutlineClear } from 'react-icons/ai';
import { FaFileCsv } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../services/api';

import { OptionFilter, OptionFilterDate } from './styles';

function ExportCSV() {
  const [loja, setLoja] = useState('');
  const [notAnonymous, setNotAnonymous] = useState(false);
  const [dateCreatedMin, setDateCreatedMin] = useState('');
  const [dateCreatedMax, setDateCreatedMax] = useState('');
  const [dateLastBuyMin, setDateLastBuyMin] = useState('');
  const [dateLastBuyMax, setDateLastBuyMax] = useState('');

  const [loading, setLoading] = useState(false);

  const handleExport = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.post('export', {
        filters: {
          loja,
          notAnonymous,
          dateCreatedMin,
          dateCreatedMax,
          dateLastBuyMin,
          dateLastBuyMax,
        },
      });

      const file = new Blob([response.data], { type: 'text/csv' });
      const a = document.createElement('a');
      document.body.appendChild(a);
      const blobUrl = window.URL.createObjectURL(file);
      a.href = blobUrl;
      a.download = 'Lista.csv';
      a.click();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao exportar lista');
    }
  }, [
    dateCreatedMax,
    dateCreatedMin,
    dateLastBuyMax,
    dateLastBuyMin,
    loja,
    notAnonymous,
  ]);

  return (
    <>
      <br />
      <br />
      <br />
      <div
        style={{
          margin: 'auto',
          background: '#fff',
          width: '95%',
          maxWidth: '700px',
          padding: '24px',
          borderRadius: '5px',
          position: 'relative',
        }}
      >
        <Container>
          <h3>Exportar em CSV</h3>

          <br />

          <AiOutlineClear
            style={{
              float: 'right',
              width: '2rem',
              height: '1.5rem',
              margin: '1rem 1rem 1rem 1rem',
              cursor: 'pointer',
            }}
            onClick={() => window.location.reload()}
          />

          <OptionFilter>
            <Form style={{ width: '40%' }}>
              <Form.Group
                controlId="exampleForm.SelectCustom"
                onChange={event => setLoja(event.target.value)}
              >
                <Form.Label>
                  {' '}
                  <strong>Loja</strong>{' '}
                </Form.Label>
                <Form.Control as="select" custom value={loja}>
                  <option>Selecione</option>
                  <option value="VIX">Vitória</option>
                  <option value="VV">Vila Velha</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </OptionFilter>

          <br />

          <OptionFilter>
            <strong style={{ marginBottom: '1rem' }}>Excluir Anônimos</strong>
            <InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Excluir Anônimos"
                onClick={() => setNotAnonymous(!notAnonymous)}
              />
            </InputGroup.Prepend>
          </OptionFilter>

          <br />

          <OptionFilterDate>
            <Button
              size="sm"
              variant="outline-success"
              style={{ background: '#ff' }}
            >
              <strong style={{ color: '#000' }}>Data mínima de cadastro</strong>
              <input
                type="date"
                id="inputDateDialy1"
                style={{ borderRadius: '5px', marginLeft: '1rem' }}
                onChange={event => setDateCreatedMin(event.target.value)}
              />
            </Button>

            <br />

            <Button
              size="sm"
              variant="outline-success"
              style={{ background: '#ff' }}
            >
              <strong style={{ color: '#000' }}>Data máxima de cadastro</strong>
              <input
                type="date"
                id="inputDateDialy2"
                style={{ borderRadius: '5px', marginLeft: '1rem' }}
                onChange={event => setDateCreatedMax(event.target.value)}
              />
            </Button>
          </OptionFilterDate>

          <br />

          <OptionFilterDate>
            <Button
              size="sm"
              variant="outline-success"
              style={{ background: '#ff' }}
            >
              <strong style={{ color: '#000' }}>
                Data mínima de última compra
              </strong>
              <input
                type="date"
                id="inputDateDialy3"
                style={{ borderRadius: '5px', marginLeft: '1rem' }}
                onChange={event => setDateLastBuyMin(event.target.value)}
              />
            </Button>

            <Button
              size="sm"
              variant="outline-success"
              style={{ background: '#ff' }}
            >
              <strong style={{ color: '#000' }}>
                Data máxima de última compra
              </strong>
              <input
                type="date"
                id="inputDateDialy4"
                style={{ borderRadius: '5px', marginLeft: '1rem' }}
                onChange={event => setDateLastBuyMax(event.target.value)}
              />
            </Button>
          </OptionFilterDate>

          <br />

          <OptionFilter style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{ background: '#3e8f0c', width: '4rem', height: '2rem' }}
              onClick={() => handleExport()}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  style={{
                    color: '#fff',
                    width: '1rem',
                    height: '1rem',
                  }}
                />
              ) : (
                <FaFileCsv
                  style={{
                    color: '#fff',
                    width: '1rem',
                    height: '1rem',
                  }}
                />
              )}
            </Button>
          </OptionFilter>
        </Container>
      </div>
    </>
  );
}

export default ExportCSV;
