import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { Container, Form, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';

export default function AddUser() {
  const admin = useSelector(state => state.auth.admin);

  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loja, setLoja] = useState(false);
  const [loading, setLoading] = useState(false);

  const resetFields = useCallback(() => {
    setName('');
    setUsername('');
    setPassword('');
    setLoja(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      if (!name || name.length < 3) {
        toast.error('O nome deve ter mais que 3 dígitos');
        return;
      }

      if (!username || username.length < 5) {
        toast.error('O username deve ter 5 dígitos no mínimo');
        return;
      }

      if (!password || password.length < 5) {
        toast.error('A senha deve ter 5 dígitos no mínimo');
        return;
      }

      if (!loja) {
        toast.error('Selecione a loja');
        return;
      }

      setLoading(true);

      const response = await api.post('user/create', {
        name,
        username,
        password,
        loja,
      });

      const { err, success } = response.data;

      if (err) {
        toast.error(err);
        setLoading(false);
        return;
      }

      if (success) {
        toast.success(success);
        setLoading(false);
        resetFields();
      }

      setLoading(false);
    } catch (error) {
      toast.error('Erro no servidor ao cadastrar usuário');
    }
  }, [loja, name, password, resetFields, username]);

  return (
    <>
      {admin && (
        <>
          {' '}
          <br />
          <br />
          <br />
          <div
            style={{
              margin: 'auto',
              background: '#fff',
              width: '95%',
              maxWidth: '700px',
              padding: '24px',
              borderRadius: '5px',
              position: 'relative',
            }}
          >
            <Container>
              <h4>Adicionar Usuário</h4>
              <br />
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    value={name}
                    type="text"
                    placeholder="Nome do operador"
                    onChange={event => setName(event.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Usuário</Form.Label>
                  <Form.Control
                    value={username}
                    type="text"
                    placeholder="username - Mínimo 5 dídigots"
                    onChange={event => setUsername(event.target.value)}
                  />
                  <Form.Text className="text-muted">
                    Este campo será utilizado para fazer o login no sistema
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Mínimo de 5 dígitos"
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                  />
                </Form.Group>

                <Form style={{ width: '40%' }}>
                  <Form.Group
                    controlId="exampleForm.SelectCustom"
                    onChange={event => setLoja(event.target.value)}
                  >
                    <Form.Label>Loja</Form.Label>
                    <Form.Control as="select" custom value={loja}>
                      <option>Selecione</option>
                      <option value="VIX">Vitória</option>
                      <option value="VV">Vila Velha</option>
                    </Form.Control>
                  </Form.Group>
                </Form>

                <br />

                {!loading ? (
                  <Button
                    // variant="primary"
                    style={{ background: '#3e8f0c' }}
                    type="button"
                    onClick={() => handleSubmit()}
                  >
                    Cadastrar
                  </Button>
                ) : (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )}
              </Form>
            </Container>
          </div>
        </>
      )}
    </>
  );
}
