import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Container } from 'react-bootstrap';
import { MdMessage, MdLocalDining } from 'react-icons/md';
import { FiSettings } from 'react-icons/fi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { FcStatistics } from 'react-icons/fc';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { BsInfoSquare } from 'react-icons/bs';
import { BiFileFind } from 'react-icons/bi';
import { FaFileCsv } from 'react-icons/fa';

import history from '../../services/history';

import { MenuButton } from './styles';

export default function Admin() {
  const admin = useSelector(state => state.auth.admin);

  const handleClickMenu = useCallback(value => {
    if (value) {
      history.push(`/admin/${value}`);
    }
  }, []);

  return (
    <>
      {admin && (
        <>
          <br />
          <br />
          <br />
          <div
            style={{
              margin: 'auto',
              background: '#fff',
              width: '95%',
              maxWidth: '700px',
              padding: '24px',
              borderRadius: '5px',
              position: 'relative',
            }}
          >
            <Container>
              <h4>Menu</h4>
              <br />
              <div
                style={{
                  display: 'flex',
                  width: '60%',
                  justifyContent: 'space-around',
                  margin: 'auto',
                  flexWrap: 'wrap',
                  height: '80vh',
                }}
              >
                <MenuButton onClick={() => handleClickMenu('produtos')}>
                  <MdLocalDining
                    style={{ color: '#fff', width: '3rem', height: '3rem' }}
                  />
                </MenuButton>

                <MenuButton onClick={() => handleClickMenu('usuarios')}>
                  <AiOutlineUserAdd
                    style={{ color: '#fff', width: '3rem', height: '3rem' }}
                  />
                </MenuButton>

                <MenuButton onClick={() => handleClickMenu('mensagens')}>
                  <MdMessage
                    style={{ color: '#fff', width: '3rem', height: '3rem' }}
                  />
                </MenuButton>

                <MenuButton onClick={() => handleClickMenu('estatisticas')}>
                  <FcStatistics
                    style={{ color: '#fff', width: '3rem', height: '3rem' }}
                  />
                </MenuButton>

                <MenuButton onClick={() => handleClickMenu('config')}>
                  <FiSettings
                    style={{ color: '#fff', width: '3rem', height: '3rem' }}
                  />
                </MenuButton>

                <MenuButton onClick={() => handleClickMenu('termosDeUso')}>
                  <BsInfoSquare
                    style={{ color: '#fff', width: '3rem', height: '3rem' }}
                  />
                </MenuButton>

                <MenuButton onClick={() => handleClickMenu('logs')}>
                  <BiFileFind
                    style={{ color: '#fff', width: '3rem', height: '3rem' }}
                  />
                </MenuButton>

                <MenuButton onClick={() => handleClickMenu('reports')}>
                  <HiOutlineDocumentReport
                    style={{
                      color: '#fff',
                      width: '3rem',
                      height: '3rem',
                    }}
                  />
                </MenuButton>

                <MenuButton onClick={() => handleClickMenu('export')}>
                  <FaFileCsv
                    style={{
                      color: '#fff',
                      width: '3rem',
                      height: '3rem',
                    }}
                  />
                </MenuButton>
              </div>
            </Container>
          </div>
        </>
      )}
    </>
  );
}
