import React, { useState, useCallback } from 'react';

import Base64Downloader from 'common-base64-downloader-react';
import { Container, Form, Button, Spinner } from 'react-bootstrap';
import { AiOutlineClear, AiOutlineCloudDownload, AiOutlineFileExcel } from 'react-icons/ai';
import { toast } from 'react-toastify';
import api from '../../services/api';

import { OptionFilter } from './styles';

function Reports() {
  const [loja, setLoja] = useState('');
  const [reportType, setReportType] = useState([]);
  const [minDate, setMinDate] = useState('2020-01');

  const [loading, setLoading] = useState(false);
  const [xlsxBase64, setXlsxBase64] = useState('');
  const [fileName, setFileName] = useState('');

  const handleExport = useCallback(async () => {
    try {
      setXlsxBase64('');
      setLoading(true);
      const response = await api.post('reports', {
        filters: {
          loja,
          minDate,
          arrTypeReport: reportType,
        },
      });

      const { err } = response.data;
      if(err) {
        toast.error(err);
        setLoading(false);
        return;
      }

      const { fileName, base64Data } = response.data.data;

      setXlsxBase64(base64Data);
      setFileName(fileName);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao exportar lista');
    }
  }, [
    loja,
    minDate,
    reportType,
  ]);

  const setSelectedTypReport = useCallback((typeReportSelected) => {    
    reportType.push(typeReportSelected);
    setReportType(reportType);  
  }, [reportType])

  return (
    <>
      <br />
      <br />
      <br />
      <div
        style={{
          margin: 'auto',
          background: '#fff',
          width: '95%',
          maxWidth: '700px',
          padding: '24px',
          borderRadius: '5px',
          position: 'relative',
        }}
      >
        <Container>
          <h3>Relatórios</h3>

          <br />

          <AiOutlineClear
            style={{
              float: 'right',
              width: '2rem',
              height: '1.5rem',
              margin: '1rem 1rem 1rem 1rem',
              cursor: 'pointer',
            }}
            onClick={() => window.location.reload()}
          />

          <OptionFilter>
            <Form style={{ width: '40%' }}>
              <Form.Group
                controlId="exampleForm.SelectCustom"
                onChange={event => setLoja(event.target.value)}
              >
                <Form.Label>
                  {' '}
                  <strong>Loja</strong>{' '}
                </Form.Label>
                <Form.Control as="select" custom value={loja}>
                  <option>Selecione</option>
                  <option value="VIX">Vitória</option>
                  <option value="VV">Vila Velha</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </OptionFilter>

          <br />

          <OptionFilter style={{ display: 'flex', justifyContent: 'space-around'}}>
            <Form style={{ width: '40%' }}>
              <Form.Group
                controlId="form.reportType"
                //onChange={event => setReportType(event.target.value)}
              >
                <Form.Label>
                  {' '}
                  <strong>Tipo de Relatório</strong>{' '}
                </Form.Label>
                <Form.Control as="select" multiple custom value={reportType} onChange={(event) => setSelectedTypReport(event.target.value)}>
                  <option value="frequency_by_month">Frequência Mensal</option>
                  <option value="qtd_by_type_message">Quantidade por tipo de mensagem</option>
                </Form.Control>
              </Form.Group>
            </Form>

            <br />

            <Button
              size="sm"
              variant="outline-success"
              style={{ background: '#ff' }}
            >
              <strong style={{ color: '#000' }}>A partir de:</strong>
              <input
                type="month"
                id="inputMonth"
                value={minDate}
                style={{ borderRadius: '5px', marginLeft: '1rem' }}
                onChange={event => setMinDate(event.target.value)}
              />
            </Button>
          </OptionFilter>

          <br />

          <OptionFilter style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{ background: '#3e8f0c', width: '4rem', height: '2rem' }}
              onClick={() => handleExport()}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  style={{
                    color: '#fff',
                    width: '1rem',
                    height: '1rem',
                  }}
                />
              ) : (
                <AiOutlineFileExcel
                  style={{               
                    
                    width: '1.3rem',
                    height: '1.3rem',
                  }}
                />
              )}
            </Button>
          </OptionFilter>

          <br />

          <OptionFilter style={{display: 'flex', justifyContent: 'center'}}>
            {xlsxBase64 && (
               <Base64Downloader base64={xlsxBase64} downloadName={fileName}>
              <Button style={{ background: '#3e8f0c', width: '4rem', height: '2rem' }}>
               
                  <AiOutlineCloudDownload
                    style={{                    
                    // color: '#fff',
                      width: '1rem',
                      height: '1rem',
                    }}
                  />
              </Button>
                </Base64Downloader>
            )}

          </OptionFilter>
        </Container>
      </div>
    </>
  );
}

export default Reports;
