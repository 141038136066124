import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Scroll = styled(PerfectScrollbar)`
  margin: 24px 12px 12px 12px;
  list-style: none;
  text-align: justify;
  max-height: 17rem;
  background: #fff;
`;
