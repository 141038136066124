import React, { useState, useCallback, useEffect } from 'react';

import { FiUserMinus, FiUserPlus } from 'react-icons/fi';
import { Container, Row, Form, Spinner, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';
import logoBranca from '../../assets/logo.png';

export default function Loja() {
  const [ddd, setDdd] = useState(27);   
  const [celphone, setCelphone] = useState('');

  const [customer, setCustomer] = useState(false);
  const [allExclusionCustomers, setAllExclusionCustomers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [enableForm, setEnableForm] = useState(false);


  // ###### API SERVICE ######
  /**
   * Request API para obter informacoes do cliente
   */
  const requestApiGetCustomerByCelphone = useCallback(async (celphone) => {
    try {
      setLoading(true);

      const response = await api.post('customer/getCustomerWhatsappDetails', {
        celphone: `${ddd}${celphone}`,
      });

      const { err, success} = response.data;

      if (err) {
        toast.error(err);
        setLoading(false);
        return;
      }

      if (success) {
        toast.info(success);
      }     
      
      if(!err && !success){
        setCustomer(response.data);
      }

      setEnableForm(true);
      setLoading(false);
    } catch (error) {
      setEnableForm(false);          
      toast.error('Erro ao obter informações do servidor');
    }
  }, [ddd]);

  /**
 * Chamada API para obter lista com todos clientes com exclusao no envio de mensagens
 */
const requestApiListExclusionCustomers = useCallback(async () => {
  try {    
    const response = await api.get('customer/listExclusionWhatsapp');
    const { listCustomers } = response.data;
    setAllExclusionCustomers(listCustomers);
  } catch (error) {
    toast.error('Erro ao obter lista do servidor');
  }
}, []);


  const handleChangeCelphone = useCallback(
    async inputCelphone => {
      setCelphone(inputCelphone);      

      let replacedCel = String(inputCelphone).replace(' ', '');
      // Armeng para números sem o 9 na frente. (inicia com negativo - )
      // replacedCel = replacedCel.replace('-', '');
      replacedCel = replacedCel.replace('+', '');
      replacedCel = replacedCel.replace('.', '');

      if (replacedCel.length === 9) {
       await requestApiGetCustomerByCelphone(replacedCel)
      }
    },
    [requestApiGetCustomerByCelphone]
  );


  /**
 * Chamada a API para excluir ou restaurar envio de mensagens para cliente
 */

const requestApiExcludeWhatsappMessage = useCallback(async (payload) => {
  setLoading(true);

  try {
    const response = await api.put('customer/manageCustomerWhatsappMessages', payload);
    const { err, success } = response.data;

    if (err) {        
      toast.error(err);        
    }

    if (success) {
      toast.success(success);
    }
    setLoading(false);

    requestApiListExclusionCustomers();
    setEnableForm(false);
    setCustomer(false);
    
  } catch (error) {
    toast.error('Erro no servidor');
    setLoading(false);
  }
}, [requestApiListExclusionCustomers]); 

  /**
   * Funcao para remover um cliente da lista de envios de mensagem
   */
  const handleExcludeCustomer = useCallback(async () => {
    const payload = {
      celphone: customer.celphone,
      recuseReceiveMessages: true,
    };

    await requestApiExcludeWhatsappMessage(payload);
  }, [customer.celphone, requestApiExcludeWhatsappMessage]);

   /**
   * Funcao para restaurar um cliente da lista de envios de mensagem
   */
    const handleRestoreCustomer = useCallback(async (celphoneRestore) => {
      const payload = {
        celphone: celphoneRestore ? celphoneRestore : customer.celphone,
        recuseReceiveMessages: false,
      };
  
      await requestApiExcludeWhatsappMessage(payload);
    }, [customer.celphone, requestApiExcludeWhatsappMessage]);




/**
 * START PAGE
 */
const [fristRun, setFristRun] = useState(false);
useEffect(() => {
  if (!fristRun) {
    requestApiListExclusionCustomers();
    setFristRun(true);
  }
}, [fristRun, requestApiListExclusionCustomers]);


  return (
    <>
      <br />
      <br />
      <br />

      <div
        style={{
          margin: 'auto',
          background: '#fff',
          /*  height: '80%', */
          width: '95%',
          maxWidth: '400px',
          padding: '24px',
          borderRadius: '5px',
          position: 'relative',
        }}
      >
        <Container>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={logoBranca}
              alt="Logomarca"
              style={{
                width: '128px',
              }}
            />
          </div>

          
          <br />
          
          <h3>WhatsApp</h3>
          <br />

          <Row>
            <br />
            <div>
              <Form.Label>
                <strong>Nº Celular</strong>
              </Form.Label>
              <div style={{ display: 'flex' }}>
                <Form.Control
                  style={{ width: '4rem' }}
                  type="number"
                  max="99"
                  value={ddd}
                  defaultValue="27"
                  id="ddd"
                  onChange={event => setDdd(event.target.value)}
                />
                <Form.Control
                  type="number"
                  value={celphone}
                  placeholder="Celular do cliente"
                  style={{ width: '16rem', marginLeft: '5px' }}
                  id="celphone"
                  onChange={event => handleChangeCelphone(event.target.value)}
                />

                {loading && (
                  <Spinner animation="border" role="status" style={{}}>
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) }
              </div>
            </div>
          </Row>

          {enableForm && customer ? (
            <>
              <br />
              <br />

              <div>
                <strong>Nome: &emsp;</strong>
                <span>{customer.name}</span>
                <br />
                <br />
                <strong>Status Atual:&emsp;</strong>
                <span>{customer.recuseReceiveMessages ? 'Bloqueado' : 'Liberado'}</span>
                <br />
                <br />
                <strong>Ação:&emsp;</strong>
                {customer.recuseReceiveMessages ? (
                  // Liberar envio
                  <button
                  type="button"
                  onClick={() => handleRestoreCustomer()}
                  style={{
                    background: '#5cb85c',
                    border: 'none',
                    borderRadius: '5px',
                    width: '1.9rem',
                    height: '1.3rem',                    
                    bottom: '1.5rem',
                    right: '1.5rem',
                  }}
                >
                  <FiUserPlus
                    style={{
                      color: '#fff',
                      width: '0.8rem',
                      height: '0.8rem',
                    }}
                  />
                </button>
                ) : (
                  // Bloquear envio
                  <button
                  type="button"
                  onClick={() => handleExcludeCustomer()}
                  style={{
                    background: '#dc3545',
                    border: 'none',
                    borderRadius: '5px',
                    width: '1.9rem',
                    height: '1.3rem',                    
                    bottom: '1.5rem',
                    right: '1.5rem',
                  }}
                >
                  <FiUserMinus
                    style={{
                      color: '#fff',
                      width: '0.8rem',
                      height: '0.8rem',
                    }}
                  />
                </button>
                )}
              </div>



              {!loading ? (
                <span></span>
              ) : (
                <Spinner
                  animation="border"
                  role="status"
                  
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
            </>
          ) : null}
        </Container>


        
      </div>


      <div
        style={{
          margin: 'auto',
          background: '#fff',
          /*  height: '80%', */
          width: '95%',
          maxWidth: '400px',
          padding: '24px',
          borderRadius: '5px',
          position: 'relative',
        }}
      >


        <Container>
          <br />
          <h4>Lista de Clientes Bloqueados</h4>

          <br />
          <br />

          <Table responsive>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Data do Bloqueio</th>
                <th>Loja</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              {allExclusionCustomers && 
              allExclusionCustomers.length > 0 &&
              allExclusionCustomers.map(x => {
                  return (
                    <tr>
                      <td>{x.name}</td>

                      <td>
                        {new Intl.DateTimeFormat('pt-BR', {
                          year: '2-digit',
                          month: '2-digit',
                          day: '2-digit',
                          hour: 'numeric',
                          minute: 'numeric',
                        }).format(new Date(x.dateOfRecuseMessages))}
                      </td>
                      <td>&emsp;{x.loja}</td>
                      <td>
                      <button
                  type="button"
                  onClick={() => handleRestoreCustomer(x.celphone)}
                  style={{
                    background: '#5cb85c',
                    border: 'none',
                    borderRadius: '5px',
                    width: '1.9rem',
                    height: '1.3rem',                    
                    bottom: '1.5rem',
                    right: '1.5rem',
                  }}
                >
                  <FiUserPlus
                    style={{
                      color: '#fff',
                      width: '0.8rem',
                      height: '0.8rem',
                    }}
                  />
                </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          
        </Container>
      </div>
    </>
  );
}
