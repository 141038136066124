import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import imageCompression from 'browser-image-compression';

import {
  Container,
  Button,
  Accordion,
  Card,
  InputGroup,
  DropdownButton,
  Dropdown,
  FormControl,
  Form,
  Spinner,
  Table,
} from 'react-bootstrap';
import { FiFolder, FiFilePlus } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';
import { FaMedal } from 'react-icons/fa';
import { AiOutlinePicture } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';

import { toast } from 'react-toastify';
import api from '../../services/api';
import { MenuButton } from './styles';

export default function Admin() {
  const admin = useSelector(state => state.auth.admin);

  const [loja, setLoja] = useState('');
  const [categoriesAll, setCategoriesAll] = useState(false);
  const [productsAll, setProductsAll] = useState(false);

  const [categoryPage, setCategoryPage] = useState(false);
  const [productsPage, setProductsPage] = useState(false);

  const [category_name, setCategory_name] = useState('');
  const [week, setWeek] = useState(false);

  const [newProductId, setNewProductId] = useState(false);
  const [newProductName, setNewProductName] = useState(false);
  const [newProductDescription, setNewProductDescription] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleGetAllCategories = useCallback(async valueLoja => {
    try {
      const response = await api.get(`category/${valueLoja}`);

      const { err } = response.data;

      if (err) {
        toast.error(err);
        return;
      }

      setCategoriesAll(response.data);
    } catch (error) {
      toast.error('Não foi possível obter categorias');
    }
  }, []);

  const handleGetAllProducts = useCallback(async () => {
    if (loja === '') {
      toast.error('Por favor, atualize a página');
    }
    try {
      const response = await api.get(`category/product/loja/${loja}`);

      const { err } = response.data;
      if (err) {
        toast.error(err);
      } else if (response.data.length > 0) {
        setProductsAll(response.data);
      } else {
        toast.info('Cadastre categorias e depois cadastre os produtos');
      }
    } catch (error) {
      toast.error('Por favor, atualize a página');
    }
  }, [loja]);

  const handleGetNewProductId = useCallback(async () => {
    const response = await api.get('category/product/new');

    const { err, productId } = response.data;

    if (err) {
      toast.error(err);
      return;
    }

    if (productId) {
      setNewProductId(productId);
    }
  }, []);

  const handleCreateProduct = useCallback(
    async categoryId => {
      const newProduct = {
        categoryId,
        product_name: newProductName,
        product_description: newProductDescription,
        productId: newProductId,
      };

      try {
        const response = await api.post('category/product/new', newProduct);

        const { err, success } = response.data;

        if (err) {
          toast.error(err);
          return;
        }

        if (success) {
          toast.success(success);
          handleGetAllProducts();
          setNewProductId(false);
          setNewProductName(false);
          setNewProductDescription(false);
        }
      } catch (error) {
        toast.error('Erro ao adicionar novo produto');
      }
    },
    [handleGetAllProducts, newProductDescription, newProductId, newProductName]
  );

  const handleDeleteCategory = useCallback(
    async value => {
      try {
        const response = await api.delete(`category/${value}`);

        const { err, success } = response.data;

        if (err) {
          toast.error(err);
          return;
        }

        if (success) {
          toast.success(success);

          handleGetAllCategories(loja);
          return;
        }

        setCategoriesAll(response.data);
      } catch (error) {
        toast.error('Não foi possível excluir categoria');
      }
    },
    [handleGetAllCategories, loja]
  );

  const handleDeleteProduct = useCallback(
    async prodId => {
      try {
        const response = await api.delete(`category/product/${prodId}`);

        const { err, success } = response.data;

        if (err) {
          toast.error(err);
          return;
        }

        if (success) {
          toast.success(success);
          handleGetAllProducts();
        }
      } catch (error) {
        toast.error('Erro no servidor ao deletar produto');
      }
    },
    [handleGetAllProducts]
  );

  const handleUploadImage = useCallback(
    event => {
      const imageFile = event.target.files[0];

      const options = {
        maxSizeMB: '0.3',
        maxWidthOrHeigth: 64,
        useWebWorker: true,
      };

      imageCompression(imageFile, options)
        .then(compressedFile => {
          const data = new FormData();

          data.append('file', compressedFile);

          api.post(`/category/product/new/${newProductId}`, data).then(
            response => {
              const { err, success } = response.data;

              if (err) {
                toast.error(err);
              }
              if (success) {
                toast.info(success);
              }
            },
            () => {
              toast.error('Erro ao fazer upload de imagem');
            }
          );
        })
        .catch(() => {
          toast.error(
            'Erro ao converter imagem. Tente enviar uma com tamanho menor'
          );
        });
    },
    [newProductId]
  );

  const handleClickMenu = useCallback(
    value => {
      if (value === 'category') {
        setProductsPage(false);
        setCategoryPage(true);
      }

      if (value === 'products') {
        setCategoryPage(false);
        setProductsPage(true);
        handleGetAllProducts();
      }
    },
    [handleGetAllProducts]
  );

  const handleCreateCategory = useCallback(async () => {
    if (!category_name || category_name === '') {
      toast.error('Digite o nome da categoria');
      return;
    }

    if (!loja || loja === '') {
      toast.error('Por favor, atualize a página');
      return;
    }

    try {
      setLoading(true);
      const response = await api.post('category/new', {
        category_name,
        week,
        loja,
      });

      setLoading(false);

      const { err, success } = response.data;

      if (err) {
        toast.error(err);
        return;
      }

      if (success) {
        toast.success(success);

        handleGetAllCategories(loja);

        setCategory_name('');
      }
    } catch (error) {
      setLoading(false);
      toast.success('Erro ao registrar nova categoria');
    }
  }, [category_name, handleGetAllCategories, loja, week]);

  const handleSetWeek = useCallback(() => {
    setWeek(!week);
  }, [week]);

  return (
    <>
      {admin && (
        <>
          <br />
          <br />
          <br />
          <div
            style={{
              margin: 'auto',
              background: '#fff',
              width: '95%',
              maxWidth: '700px',
              padding: '24px',
              borderRadius: '5px',
              position: 'relative',
            }}
          >
            <Container>
              <h4>Informações sobre Produtos</h4>
              <br />
              <InputGroup className="mb-3">
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-success"
                  title="Loja"
                  id="lojaSelect"
                >
                  <Dropdown.Item
                    value="VIX"
                    onClick={() => {
                      setLoja('VIX');
                      handleGetAllCategories('VIX');
                    }}
                  >
                    Vitória
                  </Dropdown.Item>

                  <Dropdown.Item
                    value="VV"
                    onClick={() => {
                      setLoja('VV');
                      handleGetAllCategories('VV');
                    }}
                  >
                    Vila Velha
                  </Dropdown.Item>
                </DropdownButton>
                <FormControl
                  aria-describedby="basic-addon1"
                  value={loja}
                  disabled
                />
              </InputGroup>

              <br />

              {loja && loja !== '' && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-around',
                    margin: 'auto',
                  }}
                >
                  <MenuButton onClick={() => handleClickMenu('category')}>
                    <FiFolder
                      style={{ color: '#fff', width: '1.5rem', height: '2rem' }}
                    />
                    <span
                      style={{
                        color: '#fff',
                        fontSize: '1rem',
                        marginTop: '2px',
                      }}
                    >
                      &nbsp; Categorias
                    </span>
                  </MenuButton>

                  <MenuButton onClick={() => handleClickMenu('products')}>
                    <FiFilePlus
                      style={{ color: '#fff', width: '1.5rem', height: '2rem' }}
                    />
                    <span
                      style={{
                        color: '#fff',
                        fontSize: '1rem',
                        marginTop: '2px',
                      }}
                    >
                      &nbsp; Produtos
                    </span>
                  </MenuButton>
                </div>
              )}

              <br />
              <br />

              {categoryPage && loja && loja !== ' ' && (
                <div>
                  <Accordion>
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                        >
                          <Button style={{ background: ' #3e8f0c' }}>
                            <MdAdd
                              style={{
                                color: '#fff',
                                height: '1.1rem',
                                width: '1.1rem',
                              }}
                            />
                            <span
                              style={{
                                color: '#fff',
                                fontSize: '1rem',
                                marginTop: '2px',
                              }}
                            >
                              &nbsp; Categoria
                            </span>
                          </Button>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div style={{ display: 'flex' }}>
                            <InputGroup
                              size="sm"
                              className="mb-3"
                              style={{ width: '50%' }}
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-sm">
                                  Nome
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                value={category_name}
                                aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"
                                onChange={event =>
                                  setCategory_name(event.target.value)
                                }
                              />
                            </InputGroup>

                            {!loading ? (
                              <Button
                                variant="primary"
                                style={{ height: '2.1rem', marginLeft: '2rem' }}
                                onClick={() => handleCreateCategory()}
                              >
                                Salvar
                              </Button>
                            ) : (
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            )}
                          </div>
                          <Form.Check
                            type="checkbox"
                            label="Semanal"
                            onChange={() => handleSetWeek()}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              )}

              <br />
              {/* ///////////////////////////////// TABELA DE CATEGORIAS */}

              {categoriesAll && categoryPage && loja && loja !== ' ' && (
                <div>
                  <Table striped bordered hover size="sm" responsive>
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Criado em</th>
                        <th>Destaque</th>
                        <th>Excluir</th>
                      </tr>
                    </thead>

                    <tbody>
                      {categoriesAll.map(x => {
                        return (
                          <tr>
                            <td>{x.category_name}</td>
                            <td>
                              {format(new Date(x.createdAt), 'dd/MM/yyyy')}
                            </td>
                            <td>{x.week ? 'Sim' : ''}</td>
                            <td>
                              <Button
                                size="sm"
                                variant="danger"
                                onClick={() => handleDeleteCategory(x._id)}
                              >
                                Excluir
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}

              {/* ///////////////////////////////// LISTAGEM DE PRODUTOS POR CATEGORIA */}

              <>
                {productsPage && productsAll && (
                  <Accordion>
                    {productsAll.map((value, key) => {
                      return (
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey={key}
                            >
                              <span style={{ fontWeight: 'bold' }}>
                                {value.category_name}
                              </span>

                              {value.week && (
                                <FaMedal style={{ marginLeft: '1rem' }} />
                              )}
                            </Accordion.Toggle>
                          </Card.Header>

                          <Accordion.Collapse eventKey={key}>
                            <Card.Body>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <Button
                                  size="sm"
                                  style={{
                                    background: '#3e8f0c',
                                    maxHeight: '1.8rem',
                                  }}
                                  onClick={() => handleGetNewProductId()}
                                >
                                  <MdAdd
                                    style={{
                                      color: '#fff',
                                      height: '1.1rem',
                                      width: '1.1rem',
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: '#fff',
                                      fontSize: '1rem',
                                      marginTop: '2px',
                                    }}
                                  >
                                    &nbsp; Produto
                                  </span>
                                </Button>

                                {newProductId && (
                                  <>
                                    {' '}
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginLeft: '3.5rem',
                                      }}
                                    >
                                      <Form.Control
                                        size="sm"
                                        type="text"
                                        placeholder="Nome do produto"
                                        value={newProductName || ''}
                                        onChange={event =>
                                          setNewProductName(event.target.value)
                                        }
                                      />

                                      <br />

                                      <Form.Control
                                        as="textarea"
                                        rows="2"
                                        size="sm"
                                        placeholder="Descrição do produto... Coloque aqui também o preço em Pontos"
                                        value={newProductDescription || ''}
                                        onChange={event =>
                                          setNewProductDescription(
                                            event.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <label htmlFor="file">
                                      <AiOutlinePicture
                                        style={{
                                          color: '#3e8f0c',
                                          cursor: 'pointer',
                                          marginLeft: '2rem',
                                          width: '2.5rem',
                                          height: '1.6rem',
                                        }}
                                      />

                                      <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleUploadImage}
                                        id="file"
                                        style={{
                                          display: 'hidden',
                                          background: '#fff',
                                          width: '1px',
                                          height: '1px',
                                        }}
                                      />
                                    </label>{' '}
                                  </>
                                )}

                                {newProductDescription && (
                                  <Button
                                    size="sm"
                                    onClick={() =>
                                      handleCreateProduct(value.category_id)
                                    }
                                    style={{
                                      marginLeft: '3rem',
                                      marginTop: '3rem',
                                      maxHeight: '2rem',
                                    }}
                                  >
                                    Salvar
                                  </Button>
                                )}
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>

                          {/* COM PRODUTOS CADASTRADOS */}
                          {value.productsOfCategory &&
                            value.productsOfCategory.length > 0 &&
                            value.productsOfCategory.map(prod => {
                              return (
                                <>
                                  <Accordion.Collapse eventKey={key}>
                                    <Card.Body>
                                      <hr
                                        style={{
                                          display: 'block',
                                          height: '2px',
                                          border: '0',
                                          borderTop: '1px solid #eee',
                                          padding: '0',
                                        }}
                                      />

                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                        }}
                                      >
                                        <img
                                          src={prod.picture_path}
                                          alt={value.product_name}
                                          style={{
                                            width: '64px',
                                            borderRadius: '5px',
                                            border: 'solid 1px #eee',
                                          }}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginLeft: '2rem',
                                            width: '100%',
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              width: '100%',
                                              justifyContent: 'space-between',
                                            }}
                                          >
                                            <span
                                              style={{ fontWeight: 'bold' }}
                                            >
                                              {prod.product_name}
                                            </span>
                                            <BsFillTrashFill
                                              onClick={() =>
                                                handleDeleteProduct(prod._id)
                                              }
                                              style={{
                                                color: '#d9534f',
                                                cursor: 'pointer',
                                              }}
                                            />
                                          </div>
                                          <p>{prod.product_description}</p>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </>
                              );
                            })}
                        </Card>
                      );
                    })}
                  </Accordion>
                )}
              </>
            </Container>
          </div>
        </>
      )}
    </>
  );
}
