import React, { useCallback, useState } from 'react';

import {
  Container,
  Table,
  InputGroup,
  FormControl,
  Button,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';

function LogInfo() {
  const [filter, setFilter] = useState('');
  const [logs, setLogs] = useState([]);

  const handleSearch = useCallback(async () => {
    try {
      const response = await api.post('logs', { filter });

      setLogs(response.data);
    } catch (error) {
      toast.error('Erro ao obter logs');
    }
  }, [filter]);

  return (
    <>
      <br />
      <br />
      <br />
      <div
        style={{
          margin: 'auto',
          background: '#fff',
          width: '95%',
          maxWidth: '700px',
          padding: '24px',
          borderRadius: '5px',
          position: 'relative',
        }}
      >
        <Container>
          <h3>Buscar Logs</h3>

          <br />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <InputGroup className="mb-1">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Filtro</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Telefone"
                aria-label="Filtro Telefone"
                aria-describedby="basic-addon1"
                value={filter}
                onChange={e => setFilter(e.target.value)}
                style={{ maxWidth: '90%' }}
              />
              <Button
                variant="primary"
                size="sm"
                style={{ marginLeft: '1rem' }}
                onClick={() => handleSearch()}
              >
                Buscar
              </Button>
            </InputGroup>
          </div>

          <br />

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Logs</th>
              </tr>
            </thead>

            <tbody>
              {logs &&
                logs.map(x => (
                  <tr>
                    <td>{x.log}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Container>
      </div>
    </>
  );
}

export default LogInfo;
