import styled from 'styled-components';

export const OptionFilter = styled.div`
  border: solid 1px #3e8f0c;
  border-radius: 5px;
  padding: 1rem 1rem 1rem 1rem;
`;

export const OptionFilterDate = styled.div`
  display: flex;
  justify-content: space-around;
  border: solid 1px #3e8f0c;
  border-radius: 5px;
  padding: 1rem 1rem 1rem 1rem;

  button {
    width: 45%;
  }
`;
