import React, { useState, useCallback } from 'react';

import { AiOutlineClear } from 'react-icons/ai';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { Container, Row, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';
import logoBranca from '../../assets/logo.png';

export default function Loja() {
  const [ddd, setDdd] = useState(27);
  // const [celphone, setCelphone] = useState('996139321');
  const [celphone, setCelphone] = useState('');
  const [customerPoints, setCustomerPoints] = useState(0);
  const [nameCustomer, setNameCustomer] = useState('');
  const [value, setValue] = useState('');
  const [usePoints, setUsePoints] = useState(false); // Checkbox para saber se precisa debitar pontos
  const [manyPoints, setManyPoints] = useState('');
  const [loading, setLoading] = useState(false);
  const [ifood, setIfood] = useState(false); // Checkbox para saber se é cliente iFood

  const [enableForm, setEnableForm] = useState(false);

  const handleChangeCheckbox = useCallback(
    event => {
      if (event === 'debit') {
        setUsePoints(!usePoints);
      }

      if (event === 'ifood') {
        setIfood(!ifood);
      }
    },
    [ifood, usePoints]
  );

  const resetFields = useCallback(keelCelphone => {
    if (!keelCelphone) {
      setCelphone('');
    }
    setNameCustomer('');
    setValue('');
    setUsePoints(false);
    setManyPoints('');
    setLoading(false);
    setCustomerPoints(0);
    setIfood(false);
  }, []);

  const handleChangeCelphone = useCallback(
    async inputCelphone => {
      setCelphone(inputCelphone);
      setCustomerPoints(0);

      let replacedCel = String(inputCelphone).replace(' ', '');
      // Armeng para números sem o 9 na frente. (inicia com negativo - )
      // replacedCel = replacedCel.replace('-', '');
      replacedCel = replacedCel.replace('+', '');
      replacedCel = replacedCel.replace('.', '');

      if (replacedCel.length === 9) {
        try {
          setLoading(true);

          const response = await api.post('customer/getFlashCustomer', {
            celphone: `${ddd}${replacedCel}`,
          });

          const { err, success, name, actualPoints } = response.data;

          if (err) {
            toast.error(err);
            setLoading(false);
            return;
          }

          if (success) {
            toast.info(success);
          }

          resetFields(true);

          if (name) {
            setNameCustomer(name);
          }

          if (actualPoints) {
            setCustomerPoints(actualPoints);
          }

          setEnableForm(true);
          setLoading(false);
        } catch (error) {
          setEnableForm(false);
          resetFields();
          toast.error('Erro ao obter informações do servidor');
        }
      }
    },
    [ddd, resetFields]
  );

  const handleSubmit = useCallback(async () => {
    const validateForm = () => {
      if (`${ddd}${celphone}`.length !== 11) {
        toast.error('Verifique o número do celular');
        return false;
      }
      if (!value || value === '') {
        toast.error('Insira o valor da venda');
        return false;
      }

      if(Number(value) && Number(value) > 600) {
        toast.error('Valor máximo permitido para venda é: R$600,00.\nCaso necessário, favor realizar um lançamento no valor de R$600,00 e outro com o valor residual.')
        return false;
      }

      if (!nameCustomer || nameCustomer === '') {
        toast.error('Insira o nome do cliente');
        return false;
      }

      if (usePoints && (!manyPoints || manyPoints === '')) {
        toast.error('Insira a quantidade de pontos para serem resgatados');
        return false;
      }

      if (manyPoints && manyPoints < 0) {
        toast.error('Não é permitido resgate negativo de pontos');
        return false;
      }

      if (usePoints && manyPoints > customerPoints) {
        toast.error('O resgate deve ser menor que a quantidade de pontos');
        return false;
      }

      return true;
    };

    const validForm = validateForm();

    if (!validForm) {
      return;
    }

    const payload = {
      celphone: `${ddd}${celphone}`,
      valueSell: value,
      name: nameCustomer,
      debitPoints: manyPoints,
      ifood,
    };

    setLoading(true);
    try {
      const response = await api.post('customer/manageSell', payload);

      const { err, success, actualPoints } = response.data;

      if (err) {
        setLoading(false);
        toast.error(err);
        return;
      }

      if (success) {
        toast.success(success);
      }
      setLoading(false);

      toast.success(`Pontos atuais: ${actualPoints}`);
      resetFields();
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao cadastrar venda');
    }
  }, [
    celphone,
    customerPoints,
    ddd,
    ifood,
    manyPoints,
    nameCustomer,
    resetFields,
    usePoints,
    value,
  ]);

  return (
    <>
      <br />
      <br />
      <br />

      <div
        style={{
          margin: 'auto',
          background: '#fff',
          /*  height: '80%', */
          width: '95%',
          maxWidth: '400px',
          padding: '24px',
          borderRadius: '5px',
          position: 'relative',
        }}
      >
        <Container>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={logoBranca}
              alt="Logomarca"
              style={{
                width: '128px',
              }}
            />
          </div>
          <Row>
            <br />
            <div>
              <Form.Label>
                <strong>Nº Celular</strong>
              </Form.Label>
              <div style={{ display: 'flex' }}>
                <Form.Control
                  style={{ width: '4rem' }}
                  type="number"
                  max="99"
                  value={ddd}
                  defaultValue="27"
                  id="ddd"
                  onChange={event => setDdd(event.target.value)}
                />
                <Form.Control
                  type="number"
                  value={celphone}
                  placeholder="Celular do cliente"
                  style={{ width: '16rem', marginLeft: '5px' }}
                  id="celphone"
                  onChange={event => handleChangeCelphone(event.target.value)}
                />

                {loading ? (
                  <Spinner animation="border" role="status" style={{}}>
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <button
                    style={{
                      width: '1vw',
                      height: '3vh',
                      background: 'none',
                      border: 'none',
                      marginTop: '1vh',
                      marginLeft: '1rem',
                    }}
                    type="button"
                    onClick={() => resetFields()}
                  >
                    <AiOutlineClear />
                  </button>
                )}
              </div>
            </div>
          </Row>

          {enableForm && celphone && celphone.length === 9 ? (
            <>
              <br />
              <br />

              <Row>
                <div>
                  <Form.Label>
                    <strong>Nome do cliente</strong>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={nameCustomer}
                    placeholder="Nome do cliente"
                    style={{ width: '12rem' }}
                    id="nameCustomer"
                    onChange={event => setNameCustomer(event.target.value)}
                  />

                  {nameCustomer === '' && (
                    <button
                      type="button"
                      onClick={() => setNameCustomer('ANONIMO')}
                      style={{
                        background: '#d9534f',
                        border: 'none',
                        borderRadius: '5px',
                        padding: '0.1rem',
                        color: '#fff',
                        marginTop: '0.1rem',
                        fontSize: '0.8rem',
                      }}
                    >
                      Anônimo
                    </button>
                  )}
                </div>

                <div style={{ marginLeft: '3.4rem' }}>
                  <Form.Label>
                    <strong>Pontos</strong>
                  </Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    value={customerPoints}
                    style={{ width: '5rem' }}
                    id="customerPoints"
                  />
                </div>
              </Row>

              <br />
              <br />

              <Row>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label>
                      <strong>Valor da comanda</strong>
                    </Form.Label>
                    <div style={{ display: 'flex' }}>
                      R${' '}
                      <Form.Control
                        type="number"
                        value={value}
                        style={{ width: '7rem', marginLeft: '5px' }}
                        id="valueComand"
                        onChange={event => setValue(event.target.value)}
                      />
                    </div>
                  </div>

                  <Form.Group>
                    <Form.Check
                      value="ifood"
                      type="checkbox"
                      name="iFood"
                      label="iFood?"
                      id="iFood"
                      style={{
                        fontWeight: 'bold',
                        marginLeft: '100%',
                        marginTop: '50%',
                      }}
                      onChange={event =>
                        handleChangeCheckbox(event.target.value)
                      }
                    />
                  </Form.Group>
                </div>
              </Row>

              <br />
              <br />

              {!ifood && customerPoints && customerPoints > 0 ? (
                <>
                  <Row>
                    <Form.Group>
                      <Form.Check
                        value="debit"
                        type="checkbox"
                        name="terms"
                        label="Resgate de pontos?"
                        id="validationFormik0"
                        style={{ fontWeight: 'bold' }}
                        onChange={event =>
                          handleChangeCheckbox(event.target.value)
                        }
                      />
                    </Form.Group>
                  </Row>
                  {usePoints ? (
                    <Row>
                      <div style={{ display: 'flex' }}>
                        <strong>Quantos?</strong>{' '}
                        <Form.Control                        
                          type="text"
                          value={manyPoints}
                          style={{ width: '7rem', marginLeft: '5px' }}
                          id="valueDebitPoints"
                          onChange={event => {
                            let arr = ['', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
                            if(arr.includes(event.target.value.slice(-1))) {
                              setManyPoints(event.target.value);
                            }                                                    
                          }}
                        />
                      </div>
                    </Row>
                  ) : null}{' '}
                </>
              ) : null}

              {!loading ? (
                <button
                  type="button"
                  onClick={() => handleSubmit()}
                  style={{
                    background: '#5cb85c',
                    border: 'none',
                    borderRadius: '5px',
                    width: '4rem',
                    height: '3rem',
                    position: 'absolute',
                    bottom: '1.5rem',
                    right: '1.5rem',
                  }}
                >
                  <BsFillPlusCircleFill
                    style={{
                      color: '#fff',
                      width: '1.5rem',
                      height: '1.5rem',
                    }}
                  />
                </button>
              ) : (
                <Spinner
                  animation="border"
                  role="status"
                  style={{
                    background: '#5cb85c',
                    border: 'none',
                    borderRadius: '5px',
                    width: '4rem',
                    height: '3rem',
                    position: 'absolute',
                    bottom: '1.5rem',
                    right: '1.5rem',
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
            </>
          ) : null}
        </Container>
      </div>
    </>
  );
}
