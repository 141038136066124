/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FiHome } from 'react-icons/fi';
import { logoff } from '../../store/modules/auth/actions';
import { Header } from './styles';
import history from '../../services/history';

export function HeaderComponentUnsigned() {
  return (
    <Header>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          position: 'relative',
          cursor: 'pointer',
          fontSize: '1.2rem',
        }}
        onClick={() => history.push('/')}
      >
        <FiHome style={{ color: '#fff', position: 'absolute', top: '2.7px' }} />
        <span style={{ color: '#fff' }}>&emsp;&nbsp;Inicio</span>
      </div>
    </Header>
  );
}

export function HeaderComponentSigned() {
  const admin = useSelector(state => state.auth.admin);
  const loja = useSelector(state => state.auth.loja);
  let formattedLoja = '';
  if(loja === 'VIX') formattedLoja = 'Vitória';
  if(loja === 'VV') formattedLoja = 'Vila Velha';

  const dispatch = useDispatch();
  return (
    <Header>
      <nav>
        <Link to="/">Inicio</Link>

        <span>|</span>

        <Link to="/loja">Loja</Link>

        <span>|</span>

        <Link to="/historico">Histórico</Link>

        <span>|</span>

        <Link to="/whatsapp">WhatsApp</Link>

        {admin && (
          <>
            <span>|</span>
            <Link to="/admin">Admin</Link>
          </>
        )}

        <span>|</span>
        <button
          type="button"
          style={{ background: 'none', border: 'none' }}
          onClick={() => {
            dispatch(logoff());
            this.location.reload();
          }}
        >
          <a href="/login">Sair</a>
        </button>
        {/*  <Link to="login">Sair</Link> */}
      </nav>

      <div>
        <span>{formattedLoja}</span>
      </div>
    </Header>
  );
}
