import axios from 'axios';

const serverUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://api.raizbrasiltapiocaria.com.br'
    : 'http://192.168.1.8:3777';

const api = axios.create({
  baseURL: serverUrl,
});

export default api;
