import React, { useEffect, useCallback, useState } from 'react';

import { Container, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';
import logoBranca from '../../assets/logo.png';

export default function Historic() {
  const [historic, setHistoric] = useState(false);

  const getHistoricData = useCallback(async () => {
    try {
      const response = await api.get('loja/historic');

      const { err, historicData } = response.data;

      if (err) {
        toast.error(err);
        return;
      }

      if (historicData) {
        setHistoric(historicData);
      }
    } catch (error) {
      toast.error('Não foi possível obter o histórico');
    }
  }, []);

  useEffect(() => {
    if (!historic) {
      getHistoricData();
    }
  }, [getHistoricData, historic]);

  return (
    <>
      <br />
      <br />
      <br />
      <div
        style={{
          margin: 'auto',
          background: '#fff',

          width: '95%',
          maxWidth: '700px',
          padding: '24px',
          borderRadius: '5px',
          position: 'relative',
        }}
      >
        <Container>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={logoBranca}
              alt="Logomarca"
              style={{
                width: '128px',
              }}
            />
          </div>

          <br />

          <Table responsive>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Data</th>
                <th>Saldo de Pontos</th>
              </tr>
            </thead>
            <tbody>
              {historic &&
                historic.map(x => {
                  return (
                    <tr>
                      <td>{x.name}</td>

                      <td>
                        {new Intl.DateTimeFormat('pt-BR', {
                          year: '2-digit',
                          month: '2-digit',
                          day: '2-digit',
                          hour: 'numeric',
                          minute: 'numeric',
                        }).format(new Date(x.updatedAt))}
                      </td>
                      <td>&emsp;{x.actual_points}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Container>
      </div>
    </>
  );
}
