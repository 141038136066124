import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Pie, Line } from 'react-chartjs-2';
import { detect } from 'detect-browser';
import { FiRefreshCcw } from 'react-icons/fi';
import { AiOutlineLineChart } from 'react-icons/ai'

import {
  Container,
  InputGroup,
  DropdownButton,
  Dropdown,
  FormControl,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';

import { toast } from 'react-toastify';
import api from '../../services/api';

export default function Statistics() {
  const admin = useSelector(state => state.auth.admin);

  const [browserName, setBrowserName] = useState(false);
  useEffect(() => {
    if (!browserName) {
      const browser = detect();
      setBrowserName(browser.name);
    }
  }, [browserName]);

  const [loja, setLoja] = useState('');
  const [dialySelected, setDialySelected] = useState(false);
  const [monthSelected, setMonthSelected] = useState(false);
  const [yearSelected, setYearSelected] = useState(false);

  const [yearTarget, setYearTarget] = useState(new Date().getFullYear());

  const [totalPointsNormal, setTotalPointsNormal] = useState(false);
  const [totalPointsIfood, setTotalPointsIfood] = useState(false);
  const [totalPointsResgated, setTotalPointsResgated] = useState(false);
  const [totalSellNormal, setTotalSellNormal] = useState(false);
  const [totalSellIfood, setTotalSellIfood] = useState(false);
  const [pizzaPoints, setPizzaPoints] = useState(false);

  const resetCharts = useCallback(() => {
    setTotalPointsNormal(false);
    setTotalPointsIfood(false);
    setTotalPointsResgated(false);
    setTotalSellNormal(false);
    setTotalSellIfood(false);
    setPizzaPoints(false);
  }, []);

  const handleSelectChart = useCallback(
    value => {
      resetCharts();

      switch (value) {
        case 'totalPointsNormal':
          setTotalPointsNormal(true);
          break;
        case 'totalPointsIfood':
          setTotalPointsIfood(true);
          break;
        case 'totalPointsResgated':
          setTotalPointsResgated(true);
          break;
        case 'totalSellNormal':
          setTotalSellNormal(true);
          break;
        case 'totalSellIfood':
          setTotalSellIfood(true);
          break;
        case 'pizzaPoints':
          setPizzaPoints(true);
          break;

        default:
          break;
      }
    },
    [resetCharts]
  );

  /**
   * VALUES
   * statisticsDataDay: {
   * total_points_created_normal,
   * total_points_created_ifood,
   * total_points_resgated,
   * total_sell_ifood,
   * total_sell_normal,
   * date,
   * loja,
   * ifoodUsers
   * }
   */
  const [statisticsDataDay, setStatisticsDataDay] = useState(false);

  /**
   * CHARTS
   * statisticsDataCharts: {
   * totalPointsNormal,
   * totalPointsIfood,
   * totalPointsResgated,
   * totalSellNormal,
   * totalSellIfood,
   * pizzaPoints,
   * }
   */
  const [statisticsDataCharts, setStatisticsDataCharts] = useState(false);


  const handleGetStatisticData = useCallback(
    async (date, type) => {
      if (!loja || loja === '' || !date) {
        toast.error('Selecione a loja e a data');
      }
      const response = await api.post('statistics', { loja, date, type });

      const { err } = response.data;
      if (err) {
        toast.error(err);
      } else {
        switch(type){
            case 'dialy':
              setStatisticsDataDay(false);
              setStatisticsDataDay(response.data);
              break;

            case 'monthly':
              setStatisticsDataCharts(false);
              resetCharts();
              setStatisticsDataCharts(response.data);
              break;

            case 'yearly':
              setStatisticsDataCharts(false);
              resetCharts();
              setStatisticsDataCharts(response.data);
              break;
            
            default:
              break;
        }     
      }
    },
    [loja, resetCharts]
  );

  const handleChangePage = useCallback(
    event => {
      if (event === 'dialyPage') {
        resetCharts();
        setDialySelected(true);
        setMonthSelected(false);
        setYearSelected(false);

        resetDataSetData();
      }


      if (event === 'monthPage') {
        setMonthSelected(true);
        setYearSelected(false);
        setDialySelected(false);

        resetDataSetData();
      }


      if (event === 'yearPage') {
        setYearSelected(true);
        setMonthSelected(false);
        setDialySelected(false);

        resetDataSetData();
        
      }
    },
    [resetCharts]
  );

  /**
   * UTIL
   */

  /**
   * Reseta dados de estatisticas de graficos
   */
  function resetDataSetData(){    
    setStatisticsDataDay(false);
    setStatisticsDataCharts(false);
  }

  return (
    <>
      {admin && (
        <>
          <br />
          <br />
          <br />

          <div
            style={{
              margin: 'auto',
              background: '#fff',
              /* height: '65%', */
              width: '95%',
              maxWidth: '700px',
              padding: '24px',
              borderRadius: '5px',
              position: 'relative',
            }}
          >
            <Container>
              <h4>Estatísticas</h4>

              <br />

              <div style={{ display: 'flex', margin: '0 auto' }}>
                <InputGroup className="mb-3">
                  <DropdownButton
                    as={InputGroup.Prepend}
                    variant="outline-secondary"
                    title="Loja"
                    id="lojaSelect"
                  >
                    <Dropdown.Item
                      value="VIX"
                      onClick={() => {
                        setLoja('VIX');
                        resetDataSetData();
                      }}
                    >
                      Vitória
                    </Dropdown.Item>

                    <Dropdown.Item
                      value="VV"
                      onClick={() => {
                        setLoja('VV');
                        resetDataSetData();
                      }}
                    >
                      Vila Velha
                    </Dropdown.Item>
                  </DropdownButton>
                  <FormControl
                    aria-describedby="basic-addon1"
                    value={loja}
                    disabled
                  />
                </InputGroup>
              </div>

              {loja && loja !== '' && (
                <div>
                  <ToggleButtonGroup type="radio" name="changePage">
                    <ToggleButton
                      value="addMessagePage"
                      onClick={() => handleChangePage('dialyPage')}
                      style={{ background: '#3e8f0c' }}
                    >
                      Por Dia
                    </ToggleButton>
                    <ToggleButton
                      value="editMessagePage"
                      onClick={() => handleChangePage('monthPage')}
                      style={{ marginLeft: '1rem', background: '#3e8f0c' }}
                    >
                      Por Mês
                    </ToggleButton>
                    <ToggleButton
                      value="porAno"
                      onClick={() => handleChangePage('yearPage')}
                      style={{ marginLeft: '1rem', background: '#3e8f0c' }}
                    >
                      Por Ano
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              )}

              <br />

              {dialySelected && (
                <div>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    style={{ background: '#3e8f0c' }}
                  >
                    <span style={{ color: '#fff' }}>Data</span>
                    <input
                      type="date"
                      id="inputDateDialy"
                      style={{ borderRadius: '5px', marginLeft: '1rem' }}
                      onChange={event =>
                        handleGetStatisticData(event.target.value, 'dialy')
                      }
                    />
                  </Button>

                  <FiRefreshCcw
                    style={{ marginLeft: '1rem', cursor: 'pointer' }}
                    onClick={() => {
                      handleGetStatisticData(
                        document.getElementById('inputDateDialy').value, 'dialy'
                      );
                    }}
                  />
                </div>
              )}

              {monthSelected && (
                <div>
                  <Button
                    size="sm"
                    variant="outline-success"
                    style={{ background: '#3e8f0c' }}
                  >
                    <span style={{ color: '#fff' }}>Data</span>
                    <input
                      type={
                        browserName === ('firefox' || 'safari')
                          ? 'date'
                          : 'month'
                      }
                      id="inputDateMonth"
                      style={{ borderRadius: '5px', marginLeft: '1rem' }}
                      onChange={event =>
                        handleGetStatisticData(event.target.value, 'monthly')
                      }
                    />
                  </Button>

                  <FiRefreshCcw
                    style={{ marginLeft: '1rem', cursor: 'pointer' }}
                    onClick={() => {
                      resetCharts();
                      handleGetStatisticData(
                        document.getElementById('inputDateMonth').value,
                        'monthly'
                      );
                    }}
                  />
                </div>
              )}

              {yearSelected && (
                <div>
                  <Button
                    size="sm"
                    variant="outline-success"
                    style={{ background: '#3e8f0c' }}
                  >
                    <span style={{ color: '#fff' }}>Data</span>
                    <input type="number" min="2018" max="2099" step="1" value={yearTarget}
                     id="inputDateYear"
                     style={{ borderRadius: '5px', marginLeft: '1rem' }}
                     onChange={event =>
                       setYearTarget(event.target.value, 'yearly')                       
                     }
                    />                    
                  </Button>


                  <AiOutlineLineChart 
                    style={{ marginLeft: '1rem', cursor: 'pointer' }}
                    onClick={() => handleGetStatisticData(yearTarget, 'yearly')}
                  />
                  
                  <FiRefreshCcw
                    style={{ marginLeft: '3rem', cursor: 'pointer' }}
                    onClick={() => {
                      resetCharts();
                      handleGetStatisticData(
                        document.getElementById('inputDateYear').value,
                        'yearly'
                      );
                    }}
                  />
                </div>
              )}

              <br />

              {statisticsDataDay && (
                <Container style={{ maxWidth: '15rem' }}>
                  <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        id="inputGroup-sizing-sm"
                        style={{ width: '10rem' }}
                      >
                        Pontos Normais
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      value={
                        statisticsDataDay.total_points_created_normal || '0'
                      }
                    />
                  </InputGroup>

                  <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        id="inputGroup-sizing-sm"
                        style={{ width: '10rem' }}
                      >
                        Pontos iFood
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      value={
                        statisticsDataDay.total_points_created_ifood || '0'
                      }
                    />
                  </InputGroup>

                  <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        id="inputGroup-sizing-sm"
                        style={{ width: '10rem' }}
                      >
                        Total Pts Resgatados
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      value={statisticsDataDay.total_points_resgated || '0'}
                    />
                  </InputGroup>

                  <br />

                  <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        id="inputGroup-sizing-sm"
                        style={{ width: '10rem' }}
                      >
                        Total Vendas Normal
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      value={statisticsDataDay.total_sell_normal || '0'}
                    />
                  </InputGroup>

                  <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        id="inputGroup-sizing-sm"
                        style={{ width: '10rem' }}
                      >
                        Total Vendas iFood
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      value={statisticsDataDay.total_sell_ifood || '0'}
                    />
                  </InputGroup>

                  <br />

                  <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        id="inputGroup-sizing-sm"
                        style={{ width: '10rem' }}
                      >
                        Clientes do iFood
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      value={statisticsDataDay.ifoodUsers || '0'}
                    />
                  </InputGroup>
                </Container>
              )}

              {statisticsDataCharts && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      flexWrap: 'wrap',
                      alignContent: 'space-around',
                      height: '7rem',
                    }}
                  >
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={() => handleSelectChart('totalSellNormal')}
                    >
                      Total nº Vendas - Normal
                    </Button>
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={() => handleSelectChart('totalSellIfood')}
                    >
                      Total nº Vendas - iFood
                    </Button>
                    <Button
                      size="sm"
                      variant="info"
                      onClick={() => handleSelectChart('pizzaPoints')}
                    >
                      Pontos Normal x iFood
                    </Button>
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={() => handleSelectChart('totalPointsNormal')}
                    >
                      Pontos - Normal
                    </Button>
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={() => handleSelectChart('totalPointsIfood')}
                    >
                      Pontos - iFood
                    </Button>
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => handleSelectChart('totalPointsResgated')}
                    >
                      Pontos Resgatados
                    </Button>
                  </div>

                  <br />
                </>
              )}            
            </Container>

            <div
              style={{
                height: '80%',
                background: '#ffe',
                width: '100%',
                maxWidth: '712px',
              }}
            >
              {totalSellNormal && (
                <Line
                  data={statisticsDataCharts.totalSellNormal}
                  width={400}
                  height={280}
                  options={{ maintainAspectRatio: false }}
                />
              )}

              {totalSellIfood && (
                <Line
                  data={statisticsDataCharts.totalSellIfood}
                  width={400}
                  height={280}
                  options={{ maintainAspectRatio: false }}
                />
              )}

              {pizzaPoints && (
                <Pie
                  data={statisticsDataCharts.pizzaPoints}
                  width={400}
                  height={280}
                  options={{ maintainAspectRatio: false }}
                />
              )}

              {totalPointsNormal && (
                <Line
                  data={statisticsDataCharts.totalPointsNormal}
                  width={400}
                  height={280}
                  options={{ maintainAspectRatio: false }}
                />
              )}

              {totalPointsIfood && (
                <Line
                  data={statisticsDataCharts.totalPointsIfood}
                  width={400}
                  height={280}
                  options={{ maintainAspectRatio: false }}
                />
              )}

              {totalPointsResgated && (
                <Line
                  data={statisticsDataCharts.totalPointsResgated}
                  width={400}
                  height={280}
                  options={{ maintainAspectRatio: false }}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
