import styled from 'styled-components';

export const MenuButton = styled.button`
  width: 6rem;
  height: 6rem;
  background: none;
  border: none;
  border-radius: 5px;
  background-color: #3e8f0c;
`;
