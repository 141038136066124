/* eslint-disable no-restricted-syntax */
import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Container,
  InputGroup,
  DropdownButton,
  Dropdown,
  FormControl,
  Button,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';

export default function ConfigParams() {
  const admin = useSelector(state => state.auth.admin);

  const [lojas, setLojas] = useState(false);
  const [configData, setConfigData] = useState(false);
  const [selectedLoja, setSelectedLoja] = useState('');

  const [monthExp, setMonthExp] = useState(false);
  const [factoPoints, setFactorPoints] = useState(false);
  const [factoPointsIfood, setFactorPointsIfood] = useState(false);

  const [fristRun, setFristRun] = useState(false);

  const getConfigData = useCallback(async () => {
    const response = await api.get('config');

    const { err } = response.data;

    if (err) {
      toast.error(err);
    } else {
      const lojaArray = [];

      for (const [key] of Object.entries(response.data)) {
        lojaArray.push(key);
      }

      setLojas(lojaArray);

      /**
       * month_expires_points: Number
       * factor_points: Number
       * factor_points_ifood: Number
       */
      setConfigData(response.data);
    }
  }, []);

  const handleChangeLoja = useCallback(
    event => {
      setSelectedLoja(event);
      setMonthExp(configData[event].month_expires_points);
      setFactorPoints(configData[event].factor_points);
      setFactorPointsIfood(configData[event].factor_points_ifood);
    },
    [configData]
  );

  const resetFields = useCallback(async () => {
    setMonthExp(false);
    setFactorPoints(false);
    setFactorPointsIfood(false);
    setSelectedLoja('');

    getConfigData();
  }, [getConfigData]);

  const handleSubmit = useCallback(async () => {
    const payload = {
      month_expires_points: monthExp,
      factor_points: factoPoints,
      factor_points_ifood: factoPointsIfood,
      loja: selectedLoja,
    };

    const response = await api.post('config', payload);

    const { err, success } = response.data;

    if (err) {
      toast.error(err);
    }

    if (success) {
      toast.success(success);
      resetFields();
    }
  }, [factoPoints, factoPointsIfood, monthExp, resetFields, selectedLoja]);

  useEffect(() => {
    if (!fristRun) {
      getConfigData();
      setFristRun(true);
    }
  }, [fristRun, getConfigData]);

  return (
    <>
      {admin && (
        <>
          <br />
          <br />
          <br />
          <div
            style={{
              margin: 'auto',
              background: '#fff',
              /* height: '65%', */
              width: '95%',
              maxWidth: '700px',
              padding: '24px',
              borderRadius: '5px',
              position: 'relative',
            }}
          >
            <Container>
              <h4>Configurações</h4>
              <br />
              <InputGroup className="mb-3">
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title="Loja"
                  id="selectLoja"
                >
                  {lojas &&
                    lojas.map(x => {
                      return (
                        <Dropdown.Item
                          key={x}
                          onClick={() => handleChangeLoja(x)}
                        >
                          {x}
                        </Dropdown.Item>
                      );
                    })}
                </DropdownButton>
                <FormControl
                  aria-describedby="basic-addon1"
                  value={selectedLoja}
                />
              </InputGroup>

              <br />

              {selectedLoja && configData ? (
                <>
                  <InputGroup
                    size="sm"
                    className="mb-1"
                    style={{ maxWidth: '40%' }}
                  >
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        Validade Pontos (mês)
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      value={monthExp}
                      onChange={event => setMonthExp(event.target.value)}
                    />
                  </InputGroup>

                  <br />

                  <InputGroup
                    size="sm"
                    className="mb-1"
                    style={{ maxWidth: '40%' }}
                  >
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        Preço por Ponto
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      value={factoPoints}
                      onChange={event => setFactorPoints(event.target.value)}
                    />
                  </InputGroup>

                  <br />

                  <InputGroup
                    size="sm"
                    className="mb-1"
                    style={{ maxWidth: '40%' }}
                  >
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        Preço por Ponto - iFood
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      value={factoPointsIfood}
                      onChange={event =>
                        setFactorPointsIfood(event.target.value)
                      }
                    />
                  </InputGroup>

                  <br />
                  <br />
                  <Button
                    variant="success"
                    size="lg"
                    block
                    onClick={() => handleSubmit()}
                  >
                    Salvar
                  </Button>
                </>
              ) : null}
            </Container>
          </div>
        </>
      )}
    </>
  );
}
