/* eslint-disable no-empty */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import {
  Container,
  Button,
  DropdownButton,
  FormControl,
  InputGroup,
  Dropdown,
  Modal,
  Accordion,
  Card,
} from 'react-bootstrap';

import { toast } from 'react-toastify';

import { FaStoreAlt } from 'react-icons/fa';
import { RiWhatsappLine } from 'react-icons/ri';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import api from '../../services/api';

import logo from '../../assets/rb_logo.jpeg';
import logoBranca from '../../assets/logo.png';

export default function Catalog() {
  const [loja, setLoja] = useState('');
  const [productsDestak, setProductsDestak] = useState(false);
  const [productsAll, setProductsAll] = useState(false);

  const [terms, setTerms] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleGetTerms = useCallback(async () => {
    try {
      if (!loja) {
        toast.info('Selecione uma filial');
        return;
      }

      const response = await api.get(`terms/${loja}`);

      const { err, text } = response.data;

      if (err) {
        toast.info('Não foi possível obter informações');
        return;
      }

      if (text) {
        setTerms(text);
        handleShow();
      } else {
        toast.info('Não existe informações cadastradas');
      }
    } catch (error) {}
  }, [loja]);

  const handleGetAllProducts = useCallback(async valueLoja => {
    try {
      setProductsDestak(false);
      setProductsAll(false);
      const response = await api.get(`category/product/loja/${valueLoja}`);

      const { err } = response.data;
      if (err) {
        toast.error(err);
      } else if (response.data.length > 0) {
        const products = response.data;

        const destak = products.filter(prod => prod.week === true);
        const normal = products.filter(prod => prod.week === false);
        setProductsDestak(destak);
        setProductsAll(normal);
      }
    } catch (error) {
      toast.error('Por favor, atualize a página');
    }
  }, []);

  return (
    <>
      <br />
      <br />
      <br />

      {!loja && <br />}
      <div
        style={{
          margin: 'auto',
          background: '#fff',
          width: '95%',
          maxWidth: '700px',
          padding: '24px',
          borderRadius: '5px',
          position: 'relative',
        }}
      >
        <Container>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={logoBranca}
              alt="Logomarca"
              style={{
                width: '256px',
              }}
            />
          </div>

          <br />

          {!loja && <br />}

          <div name="Selecionar loja">
            <InputGroup className="mb-3">
              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-success"
                title="Loja"
                id="lojaSelect"
              >
                <Dropdown.Item
                  value="VIX"
                  onClick={() => {
                    setLoja('VIX');
                    handleGetAllProducts('VIX');
                  }}
                >
                  <FaStoreAlt style={{ color: '#3e8f0c' }} />
                  &nbsp; Vitória
                </Dropdown.Item>

                <Dropdown.Item
                  value="VV"
                  onClick={() => {
                    setLoja('VV');
                    handleGetAllProducts('VV');
                  }}
                >
                  <FaStoreAlt style={{ color: '#3e8f0c' }} />
                  &nbsp;Vila Velha
                </Dropdown.Item>
              </DropdownButton>
              <FormControl
                aria-describedby="basic-addon1"
                value={
                  loja !== '' && loja === 'VIX'
                    ? 'Vitória'
                    : loja === 'VV'
                    ? 'Vila Velha'
                    : 'Selecione uma filial'
                }
                style={{ borderColor: '#3e8f0c', boxShadow: '#3e8f0c' }}
              />
            </InputGroup>
          </div>

          {loja && (
            <div
              name="Contato e termos"
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '1.6rem',
              }}
            >
              <Button
                size="sm"
                type="button"
                variant="success"
                style={{ background: '#3e8f0c', width: '4rem' }}
                href={`https://api.whatsapp.com/send?phone=${
                  loja === 'VIX'
                    ? '5527993119853'
                    : loja === 'VV'
                    ? '5527992861533'
                    : ''
                }`}
              >
                <RiWhatsappLine size="30" />
              </Button>

              <Button
                size="sm"
                type="button"
                variant="success"
                style={{ background: '#3e8f0c', width: '4rem' }}
                onClick={() => handleGetTerms()}
              >
                <AiOutlineInfoCircle size="30" />
              </Button>
            </div>
          )}

          <div name="modal">
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Termos de Uso</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p style={{ whiteSpace: 'pre-line' }}>{terms}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="success"
                  size="sm"
                  onClick={handleClose}
                  style={{ background: '#3e8f0c', width: '4rem' }}
                >
                  Fechar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Container>
      </div>

      <div
        style={{
          width: '100%',
          maxWidth: '728px',
          margin: '0 auto',
          marginTop: '2rem',
        }}
      >
        {productsDestak && productsDestak[0] && (
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header style={{ background: '#3e8f0c' }}>
                <Accordion.Toggle
                  as={Button}
                  variant="success"
                  style={{ background: '#3e8f0c' }}
                  eventKey="0"
                >
                  <strong style={{ color: '#fff', fontSize: '1.4rem' }}>
                    {productsDestak[0].category_name.toUpperCase()}
                  </strong>
                </Accordion.Toggle>
              </Card.Header>

              {productsDestak[0].productsOfCategory &&
                productsDestak[0].productsOfCategory.length > 0 &&
                productsDestak[0].productsOfCategory.map(prod => {
                  return (
                    <>
                      <div
                        style={{
                          width: '100%',
                          background: '#eee',
                          height: '2px',
                        }}
                      />
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <img
                              src={prod.picture_path}
                              alt={prod.product_name}
                              style={{
                                width: '64px',

                                borderRadius: '5px',
                                border: 'solid 1px #eee',
                              }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '2rem',
                                width: '100%',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  width: '100%',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <span style={{ fontWeight: 'bold' }}>
                                  {prod.product_name}
                                </span>
                              </div>
                              <p>{prod.product_description}</p>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </>
                  );
                })}
            </Card>
          </Accordion>
        )}
      </div>

      {productsAll &&
        productsAll.length > 0 &&
        productsAll.map(category => {
          return (
            <>
              <br />
              <div
                style={{ width: '100%', maxWidth: '728px', margin: '0 auto' }}
              >
                <Accordion>
                  <Card>
                    <Card.Header style={{ background: '#3e8f0c' }}>
                      <Accordion.Toggle
                        as={Button}
                        variant="success"
                        style={{ background: '#3e8f0c' }}
                        eventKey="0"
                      >
                        <strong style={{ color: '#fff', fontSize: '1.2rem' }}>
                          {category.category_name.toUpperCase()}
                        </strong>
                      </Accordion.Toggle>
                    </Card.Header>

                    {category.productsOfCategory &&
                      category.productsOfCategory.length > 0 &&
                      category.productsOfCategory.map(prod => {
                        return (
                          <>
                            <div
                              style={{
                                width: '100%',
                                background: '#eee',
                                height: '2px',
                              }}
                            />
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <img
                                    src={prod.picture_path}
                                    alt={prod.product_name}
                                    style={{
                                      width: '5.3rem',
                                      height: '4rem',
                                      borderRadius: '5px',
                                      border: 'solid 1px #eee',
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      marginLeft: '2rem',
                                      width: '100%',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <span style={{ fontWeight: 'bold' }}>
                                        {prod.product_name}
                                      </span>
                                    </div>
                                    <p>{prod.product_description}</p>
                                  </div>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </>
                        );
                      })}
                  </Card>
                </Accordion>
              </div>
            </>
          );
        })}

      <br />
      <br />
      <br />

      {!loja && (
        <>
          <br />
          <br />
          <br />
        </>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <img
          src={logo}
          alt="Logomarca"
          style={{ width: '7rem', borderRadius: '50%' }}
        />

        <small style={{ marginTop: '1.3vh' }}>
          Todos os direitos reservados
        </small>
      </div>
    </>
  );
}
